.My-Skills-Heading {
  color: rgb(255, 255, 255);
  font-family: sans-serif;
  margin-top: 50pt;
  margin-left: 50px;
  padding: 20pt;
  width: 100vw; /* Set width to 100% of viewport width */
}

#About {
  background-color: rgb(32, 32, 32);
  background-size: cover;
  /* margin-top: 100pt; */
  width: calc(100% - 20px); /* Adjusted width with padding */
  padding-right: 20px; /* Add padding to the right side */
}

.about-me-list {
  margin: 30px;
  padding: 10px;
  max-width: 200px;
  background-color: lightslategray;
  display: flex;
  flex-direction: column;
  margin-top: -5px;
  margin: auto;
}

.skill-div {
  display: flex;
  justify-content: center;
}

.skill-list {
  margin: 20px;
  padding: 20px; /* Adjust padding for better spacing */
  width: 280px; /* Increase width to accommodate the content */
  min-height: 360px; /* Set minimum height to prevent items from going out of the box */
  border-radius: 10pt;
  border-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column; /* Ensure items are stacked vertically */
  align-items: center; /* Center items horizontally */
  justify-content: flex-start; /* Align items to the top */
  background-color: #11100f5e;
}

.skill-heading {
  color: rgb(255, 255, 255);
  margin-top: 10px; /* Add margin to separate heading from list items */
}

/* Animation for individual skill categories on hover */
.skill-list:hover {
  transform: scale(1.05); /* Scale up slightly on hover */
  transition: transform 0.3s ease; /* Smooth transition */
}

.ind-skill-list-fe,
.ind-skill-list-be,
.ind-skill-list-db,
.ind-skill-list-tt {
  background-color: transparent;
  border-style: solid;
  width: fit-content;
  border-radius: 10px;
  border-color: rgb(101, 1, 254);
  color: rgb(255, 255, 255);
  margin: 10px auto; /* Adjust margin for better alignment */
  padding: 5px 10px; /* Add padding for better spacing */
  font-family: auto;
  font-size: large;
  animation: ind-skill-list-animation 1s ease-out;
}

@keyframes ind-skill-list-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100;
  }
  from {
    transform: rotateY(90deg);
  }
  to {
    transform: rotateY(0deg);
  }
}

.circular-button {
  position: fixed;
  left: 92%;
  width: 72px;
  bottom: 5%;
  opacity: 30%;
  height: 70px;
  background-color: rgb(0, 0, 0);
  border: none;
  border-radius: 61%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: rgb(101, 1, 254);
  font-size: 1.5rem;
  outline: none;
}

.circular-button:hover {
  opacity: 100%;
  background-color: blueviolet;
}

.arrow {
  transform: translateY(-4px); /* Adjust arrow position */
}

.collapsible-trigger-whoami {
  margin-top: 10px;
  display: block;
  font-weight: 400;
  font-size: large;
  text-decoration: none;
  position: relative;
  /* background-color: rgb(164, 37, 37); */
  padding: 10pt;
  /* cursor:pointer; */
  max-width: 800pt;
  color: antiquewhite;
  &:after {
    font-family: 'FontAwesome';
    /* content: '+'; */
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    transition: transform 300ms;
  }
  &.is-open {
    &:after {
      transform: rotateZ(180deg);
    }
  }
  &.is-disabled {
    opacity: 0.5;
    background-color: rgb(235, 0, 0);
  }
}

@media only screen and (max-width: 1068px) {
  .skill-div {
    flex-direction: column;
    align-items: center;
    margin-top: auto;
  }
  #About {
    width: 120%;
    overflow-x: hidden;
  }
  .skill-list {
    margin: 10px;
  }
}
