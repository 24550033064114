body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  animation: colorChange 30s ease infinite forwards;
  background-color: rgb(101, 1, 254);
  background-size: contain;
  background-repeat: no-repeat;
  max-height: fit-content;
  overflow-x: hidden;
  width: 100vw; /* Set width to 100% of viewport width */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.Welcome{
    font-size: 30pt;
    color: rgb(0, 0, 0);
    font-family: serif;
    margin-left: 20pt;
    /* margin-top: 33pt; */
    animation: floatAndAppear 2s ease-out;
}
@keyframes floatAndAppear {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.myname{
  font-size: 56pt;
  color: antiquewhite;
  font-family: monospace;
  margin-left: 27pt;
  animation: floatAndAppear 2s ease-out;
}
@keyframes floatAndAppear {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.mydesc{
  font-size: 18pt;
  color: black;
  font-family: monospace;
  /* margin-left: 30pt; */
  animation: floatAndAppear 2s ease-out;
}
@keyframes floatAndAppear {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.quote{
  font-size: 18pt;
    color: #a4ca18;
    font-family: none;
    font-style: italic;
    margin-left: 25pt;
    margin-top: 4pt;
    animation: floatAndAppear 2s ease-out;
}


.profilepic{
  max-width: 240pt;
  max-height: 230pt;
  position: absolute;
  right: 20px;
  margin-top: 120pt;
  border-radius: 1000px;
  animation: profilepic-animation 3s ease-out;
  z-index: -1;
}

@keyframes profilepic-animation {
  
0%{
  opacity: 0;
  /* transform: translateX(50px); */
  
}
100%{
  opacity: 1;
  /* transform: translateX(0px); */
 
}
from {
  transform: rotate(90deg);
  
}
to {
  transform: rotate(0deg);
}
}
.about-me-button{
  position: absolute;
  margin-left: 430pt;
  margin-top: -100pt;
  border-style: groove;
  color: #fcfee2;
  background-color: #114d81;
  font-size: xxx-large;
  border-color: black;
  border-width: thick;
  /* outline-style: groove; */
  border-radius: 3pt;
  height: fit-content;
  width: 150pt;
  cursor: pointer;
  animation: about-me-button ease-out 3s;
}
@keyframes about-me-button {
  0%{
    opacity: 0;
    /* transform: translateX(50px); */
    
  }
  100%{
    opacity: 1;
   
  }
}

.tutorial-pointer{
  height: 50pt;
  position: absolute;
  margin-left: 547pt;
  margin-top: -34pt;
  animation: tutorial-pointer ease-out 5s;
}

@keyframes tutorial-pointer {
  0%{
    opacity: 0%;
    
  }
  70%{
    opacity: 0%;
  }
  100%{
    opacity: 100%;
   
  }
  from {
    transform: translateY(30pt) ;
    
  }
  to {
    transform: translateY(0pt) ;
    from{
      transform: translateX(130pt) ;
    }
    to{
      transform: translateX(0pt) ;
    }
  }
}
.About{
  border: solid;
}
.desktop_view{
  display: none;
  text-align: end;
}

.Intro1,.Intro2,.Intro3,.Intro4 {
  max-width: 900px;
   border-radius: 0pt;
   background-color:  rgb(101, 1, 254);
   padding: 5pt;
   display: block;
   height: 50px;
   border: solid;
   margin: 0; /* Reset margin to remove unwanted spacing */
   /* color:#ffffffd3; */
   color:  rgb(255, 249, 249);
   border: inset;
   border-color: rgb(255, 255, 255);
 }

.Intro1-show,.Intro2-show,.Intro3-show,.Intro4-show{
  margin: auto;
  position: relative;
  display: block;
  animation: fadeIn 1s forwards ease-in-out;
  font-family: serif;
  font-weight: 550;
  font-size: 19pt;
}

.Intro1-hide,.Intro2-hide,.Intro3-hide,.Intro4-hide{
  font-family: sans-serif; /* Update this to match the font family used in other intros */
  color: black;
  position: relative;
  text-align: center;
  display: none;
  font-size: 20pt;
  animation: fadeIn 1s forwards ease-in-out;
}
.Intro4-hide{
  font-family: sans-serif; /* Update this to match the font family used in other intros */
  color: black;
  position: relative;
  text-align: center;
  display: none;
  font-size: 15pt;
  animation: fadeIn 1s forwards ease-in-out;
}
.Intro1-hide,.Intro2-hide,.Intro3-hide,.Intro4-hide:hover{
  color:  rgb(101, 1, 254);
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}


.Intro1:hover  .Intro1-hide{
  cursor: pointer;
  display: block;
}
.Intro1:hover, .Intro2:hover, .Intro3:hover, .Intro4:hover{
  cursor: pointer;
  
  
}
.Intro1:hover{
  background-color: rgb(255, 255, 255);
}
.Intro2:hover{
  background-color: rgb(255, 255, 255);
}
.Intro3:hover{
  background-color: rgb(255, 255, 255);
}
.Intro4:hover{
  background-color: rgb(255, 255, 255);
}
.Intro1:hover .Intro1-show{
  display: none;
  
}


.Intro2:hover  .Intro2-hide{
  cursor: pointer;
  display: block;
}

.Intro2:hover .Intro2-show{
  display: none;
  
}



.Intro3:hover  .Intro3-hide{
  cursor: pointer;
  display: block;
}

.Intro3:hover .Intro3-show{
  display: none;
  
}


.Intro4:hover  .Intro4-hide{
  cursor: pointer;
  display: block;
}

.Intro4:hover .Intro4-show{
  display: none;
  
}
.Intro2,.Intro3,.Intro4{
  
   margin-top: -15pt;
 }

 .info-box {
  position: absolute;
  top: 100pt;
  right: 10pt;
  background-color: rgba(0, 0, 0, 0.8); 
  color: white; 
  padding: 10px;
  width: 10%; 
  height: 10%; 
  opacity: 0;
  visibility: visible;

}

.profilepic:hover .info-box{
  opacity: 1;
  visibility: visible;
  cursor: pointer;
} 
@media only screen and (max-width: 1068px) {
 body{
  background-size: auto;
  overflow-x: visible;
 }
  .profilepic {
    max-width: 50%;
    max-height: auto;
    left: 350px;
        margin-top: 20px;
      border-radius: 50%;
      position: relative;
  }
  .Welcome, .myname{
    font-size: 40pt;
  }
  .Welcome, .myname, .mydesc,.mydesc_mobile {
      margin-left: 15px;
      margin-top: 20px;
      text-align: center;
  }

  .about-me-button {
      margin-left: 15px;
      margin-top: 20px;
  }

  .tutorial-pointer {
      margin-left: calc(50% - 25px);
      margin-top: 20px;
  }
  .desktop_view{
    display: block;
    text-align: end;
  }
  .mydesc_mobile{
    display: block;
   
  }
  .mydesc{
    justify-content: center;
    margin-left: 50px;
  }
  /* .mydesc{
    display: none;
  } */
}
