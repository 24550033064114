#projects-container {
    background-color: rgb(32, 32, 32); /* Set background color */
    background-size: cover;
    margin-top: -50pt;
    padding: 20px; /* Add padding to increase the width */
    box-sizing: border-box; /* Include padding in the width calculation */
    width: 100vw; /* Set width to 100% of viewport width */
  }
  
  .My-Proj-Heading {
    color: rgb(227, 223, 234);
    padding: 20pt;
    margin-top: 20pt;
    margin-left: 40pt;
  }
  
  .Proj-heading {
    margin: 10pt;
    padding: 20pt;
    border: 2px solid #4CAF50;
    border-radius: 10pt;
    text-align: center;
    transition: background-color 0.3s ease, transform 0.3s ease; /* Add transform transition */
    background-color: transparent; /* Set background color to transparent */
  }
  
  .Proj-heading:hover {
    background-color: rgba(75, 175, 80, 0.3);
    transform: scale(1.05); /* Scale up slightly on hover */
  }
  
  .Heading {
    font-size: 19pt;
    font-family: fangsong;
    color: cornsilk;
  }
  
  .row1,
  .row2 {
    display: flex;
    margin-top: 20pt;
    overflow: hidden;
    animation: fadeIn 3s forwards ease-in-out;
  }
  
  .row1 a,
  .row2 a {
    text-decoration: none;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .github-button {
    text-align: center;
    margin-top: 20pt;
  }
  
  .github-button a {
    display: inline-block;
    padding: 10px 20px;
    border: 2px solid #80204f;
    background-color: #4CAF50;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .github-button a:hover {
    background-color: #45a049;
  }
  
  @media only screen and (max-width: 1068px) {
    #projects-container {
      width: 100%;
      overflow-x: hidden;
      margin-top: 0pt;
    }
  
    .row1,
    .row2 {
      display: grid;
      margin: 20pt;
    }
  
    .Proj-heading {
      margin: 10pt;
    }
  
    .github-button {
      margin-left: 0;
    }
  }
  