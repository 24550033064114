.copyright-text{
    font-size: small;
   align-items: end;
   margin-top: auto;
   align-items: baseline;
    margin-left:400px;

}
.f-links{
    color: black;
    font-size: small;
    font-weight: 400;
    border-bottom: solid;
    color: white;
}
#footer-div {
    background-color: rgb(0, 0, 0);
    margin-top: 12pt;
    height: 35pt;
    color: white;
    width: 100vw; /* Set width to 100% of viewport width */
  }

@media only screen and (max-width: 1068px) {
    .copyright-text{
        margin-left:1px;

    }
}