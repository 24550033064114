#Contact {
  background-color: rgb(101, 1, 254);
  background-size: cover;
  width: 100vw; /* Set width to 100% of viewport width */
}

.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px;
  margin-top: 0pt;
  border-radius: 8px;
}

form{
  border: ridge;
  background-color: rgb(255, 255, 255);
  margin-top: 30pt;
}

h2 {
  text-align: center;
  color:  rgb(101, 1, 254);
  
}

.contact-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.socials {
  text-decoration: none;
  color: white;
  margin-bottom: 10px;
  text-align: center;
  height: 20px; 
  transition: color 0.3s ease;
}

.socials img {
  height: 100%; 
  margin-right: 5px; 
}

.socials:hover {
  color:  rgb(0, 0, 0);
}

.contact-form {
  width: 100%;
  max-width: 400px;
}

.form-input {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #000000;
}

.input-name,
.input-email,
.input-message {
  width: 90%;
  padding: 8px;
  border: 1px solid #080808;
  margin-left: 10pt;
  border-radius: 4px;
}

.form-submit-btn {
  width: 50%;
  margin-top: 20pt;
  margin-left: 80pt;
  margin-bottom: 15pt;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form-submit-btn:hover {
  background-color: #0056b3;
}
h4{
  color:  rgb(101, 1, 254);
  /* text-align: center; */
  font-size: large;
  margin-left: 10pt;
}


@media only screen and (max-width: 768px)  {

   
    .input-name,
.input-email,
.input-message {
  width: 85%;
}
}

@media only screen and (max-width: 1208px)  {
  #Projects{
    width: 120%;
    overflow-x: hidden;
    /* margin: 0pt; */
    }
   .contact-form{
    margin-left: 30%;
   }
   .socials{
    margin-left: 250%;
    
   }
   .socials img{
    height: 100%;
    margin-left: -75px;
   }
  .input-name,
.input-email,
.input-message {
/* width: 70%;   */
}
.form-submit-btn {
  margin-left: 25%;
}
.socials {
  font-size: 10pt;
}
}