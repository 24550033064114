  ul {
    list-style-type: none;
    display: flex;
    justify-content: space-around;
  }
  
  .nav-li {
    display: inline;
    padding: 10px;
  }
  .nav-li:hover{
    transform: scale(0.95); 
    transition: transform 0.2s ease-out;
    cursor: pointer;
    border-bottom: solid;
    border-color: white;
    border-width: 2pt;
  
  }
  li {
    display: inline;
    padding: 10px;
  }
  li:hover{
    transform: scale(0.95); 
    transition: transform 0.2s ease-out;
    cursor: pointer;
    border-width: 2pt;
  
  }
  a{
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-size: larger;
    font-family: sans-serif;
    font-weight: 600;
  }

  
  @media only screen and (max-width: 1118px) {
    ul {
      /* flex-direction: column; */
      align-items: center;
      
    }
  
    li {
      margin-bottom: 10px;
    
    }
    
  }