#Work {
    background-color: rgb(255, 255, 255);
    background-size: cover;
    margin-top: -22px;
    width: 100vw; /* Set width to 100% of viewport width */
  }


.Companies{
    animation: heading-animation 3s  ease-out ;
    /* text-align: center; */
    margin-left: 50px;
    color:  rgb(101, 1, 254);
    font-family: sans-serif;
}
@keyframes heading-animation {
    0%{
        opacity: 0;
        
      }
      100%{
        opacity: 1;
      }
}

.Infosys-logo{
    /* position: absolute; */
    margin: 30pt;
    margin-left: 60pt;
    height: 90pt;
    width: 200pt;
}

.Wipro-logo{
    margin-left: 22pt;
    height: 148pt;
    width: 195pt;
}
.Sophos-logo{
    height: 172pt;
    width: 264pt;
    margin-left: 38pt;
    position: absolute;
    /* position: absolute; */
    /* margin-left: 422pt; */
    margin-top: 11pt;
}

.Apple-logo{
    /* position: absolute; */
    height: 102pt;
    width: 300pt;
    margin-bottom: 33pt;
    margin-left: 95pt;
}

.OU-logo{
    height: 101pt;
    width: 273pt;
    margin-left: 305pt;
    margin-bottom: 2pt;
}

.CSS_Corp-logo{
    height: 82pt;
    width: 250pt;
    margin-left: 20pt;
    margin-right: 30pt;
    margin-top: 55pt;
}

.Companies-list{
    animation: list-animation 3s  ease-out;
}
/* .work{
    
    height: 427pt;
    border-radius: 100pt;
    border: solid;
    background-color: #3498db;
} */
@keyframes list-animation {
    0%{
        opacity: 0;
      }
      100%{
        opacity: 1;
      }
      
}



.resume-button {

    bottom: 80px;
    right: 20px;
}

.resume-button a {
    position: absolute;
    height: fit-content;
    margin-left: 397pt;
    margin-top: 42pt;
    background-color: #3498db;
    color: #fff;
    text-decoration: none;
    border: 2px solid #2980b9;
    border-radius: 8px;
    font-size: 30px;
    font-weight: bold;
    width: 200pt;
    text-align: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.resume-button a:hover {
    background-color: #2980b9; /* Darker blue on hover */
    color: #fff; /* White text on hover */
    transform: translateY(-3px); /* Move button slightly up on hover */
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2); /* Slightly stronger shadow on hover */
}


@media screen and (max-width: 768px) {
    .work{
        background-color: antiquewhite;
    }
    .Companies{
        
        color: brown;
        
    }
    .Companies-list {
        display: grid;
        margin-left: -50pt;
    }
    .Wipro-logo{
        height: 100pt;
        width: 120pt;
        margin-left: 50pt;
    }
    .Apple-logo{
        height: 80pt;
        width: 160pt;
        margin-top: -120pt;
        margin-left: 200pt;
    }
    .Infosys-logo{
        height: 80pt;
        width: 160pt;
        margin-top: -20pt;
        margin-left: 130pt;
    }
    .OU-logo{
        height: 80pt;
        width: 220pt;
        margin-top: -29pt;
        margin-left: 100pt;
    }
    .CSS_Corp-logo{
        height: 50pt;
        width: 120pt;
        margin-top: -23pt;
        margin-left: 50pt;
    }
    .Sophos-logo{
        height: 100pt;
        width: 120pt;
        margin-top: -99pt;
        margin-left: 240pt;
    }
    .resume-button {
       
        margin-top: -30pt;
        margin-left: -270pt;
        /* margin-left: 240pt; */
    }
    
}


@media screen and (max-width: 1208px) {
    #Work{
        width: 120%;
        overflow-x: hidden;
        }
    .work{
        background-color: antiquewhite;
    }
    .Companies{
        
        color: brown;
        
    }
    .Companies-list {
        display: grid;
        margin-left: 50pt;
    }
    .Wipro-logo{
        height: 100pt;
        width: 120pt;
        margin-left: 50pt;
    }
    .Apple-logo{
        height: 80pt;
        width: 160pt;
        margin-top: -120pt;
        margin-left: 200pt;
    }
    .Infosys-logo{
        height: 80pt;
        width: 160pt;
        margin-top: -20pt;
        margin-left: 130pt;
    }
    .OU-logo{
        height: 80pt;
        width: 220pt;
        margin-top: -29pt;
        margin-left: 100pt;
    }
    .CSS_Corp-logo{
        height: 50pt;
        width: 120pt;
        margin-top: -23pt;
        margin-left: 50pt;
    }
    .Sophos-logo{
        height: 100pt;
        width: 120pt;
        margin-top: -99pt;
        margin-left: 240pt;
    }
    .resume-button {
       
        margin-top: -30pt;
        margin-left: -270pt;
        /* margin-left: 240pt; */
    }
    li {
        display: inline;
        padding: 10px;
        margin-left: 100pt;
    }
}

